require('typeface-nunito')

/**
 * Prevent our scroll to top on certain route changes
 */

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.state == null) return null;
  const { disableScrollUpdate } = routerProps.location.state
  return !disableScrollUpdate
}